import React, { useState, useEffect, useRef } from 'react';
import { Heart, X, MessageCircle } from 'lucide-react';

const Okeyador = () => {

    // @todo implemet the real API call

    const [posts, setPosts] = useState([
        {
            "id": 1,
            "copy": `¡XXXXXLo vivido en el primer día de la hashtag#AgroexpoCaribe2024 ha sido inolvidable! Conocer a tantas empresas colombianas apasionadas por la tecnología nos impulsa a seguir apoyándolas con soluciones innovadoras y a la vanguardia.

La experiencia ha sido increíble y queremos invitar a todas las personas a participar en estos eventos que brindan la oportunidad de crecer en cualquier ámbito.
`,
            "source": "https://media.licdn.com/dms/image/v2/D4E10AQEk4fAIix7hcQ/image-shrink_1280/image-shrink_1280/0/1721329365937?e=1724400000&v=beta&t=pWdbXRIIfI8O58VHon1DGquiXARs0FaBfG-ooZleDRw"
        },
        {
            "id": 2,
            "copy": `Creación de la marca HD1 SAS. 🎨🛠️ Este desafío fue una oportunidad para reflejar la rica cultura de La Guajira y la unión de su comunidad. 🌅👥 HD1 SAS es más que una estación de servicio; es una marca que se distingue en el mercado por su compromiso con la excelencia y la innovación. 🏆💡 `,
            "source": "https://media.licdn.com/dms/image/D4E10AQHatx8o8lxQyw/image-shrink_1280/0/1720042254785?e=1724400000&v=beta&t=DD-42Z-sNb64sENlZp8UJFN-J1vjYZRRoLHIVrPt4oU"
        },
        {
            "id": 3,
            "copy": `
            ¡Somos más de una opción! En Pah Venture, somos la integración tecnología perfecta que puede tener tu empresa.

Ayudamos a tu marca a conectar con su audiencia con estrategias de contenido y mejorando tu presencia en redes sociales y construimos comunidades leales. 📱💬

Hemos trabajado con diversas marcas reconocidas, transformando su imagen y ampliando su alcance.

Ofrecemos servicios de branding, diseño gráfico y creación de material promocional. 🎨✨
`,
            "source": "https://media.licdn.com/dms/image/v2/D4E10AQEIf5SQ1fgbeA/image-shrink_1280/image-shrink_1280/0/1721858512327?e=1724400000&v=beta&t=c7jCw3ZKgj4DXAPiISbHcwRamAhK6h6UXRfR99OdEqU"
        }
    ]);
    const [currentPost, setCurrentPost] = useState(null);
    const [swipeDirection, setSwipeDirection] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const cardRef = useRef(null);
    const startX = useRef(0);
    const startY = useRef(0);
    const isSwiping = useRef(false);

    useEffect(() => {
        if (posts.length > 0) {
            setCurrentPost(posts[0]);
        }

        document.body.style.overscrollBehavior = 'none';


        return () => {
            document.body.style.overscrollBehavior = 'auto';
        };
    }, []);


    useEffect(() => {
        if (posts.length > 0) {
            setCurrentPost(posts[0]);
        }

        document.body.style.overscrollBehavior = 'none';

        return () => {
            document.body.style.overscrollBehavior = 'auto';
        };
    }, []);

    const handleTouchStart = (e) => {
        startX.current = e.touches[0].clientX;
        startY.current = e.touches[0].clientY;
        isSwiping.current = false;
    };

    const handleTouchMove = (e) => {
        if (!cardRef.current || !isSwiping.current) return;
        const currentX = e.touches[0].clientX;
        const diff = startX.current - currentX;
        const rotation = diff / 10;
        cardRef.current.style.transform = `translateX(${-diff}px) rotate(${rotation}deg)`;
    };

    const handleTouchEnd = (e) => {
        if (!cardRef.current || !isSwiping.current) return;
        const diff = startX.current - e.changedTouches[0].clientX;
        if (Math.abs(diff) > 100) {
            if (diff > 0) {
                handleReject(currentPost);
            } else {
                handleApprove(currentPost);
            }
        } else {
            cardRef.current.style.transform = 'translateX(0) rotate(0deg)';
        }
        isSwiping.current = false;
    };

    const handleTouchMovePotential = (e) => {
        if (!isSwiping.current) {
            const currentX = e.touches[0].clientX;
            const currentY = e.touches[0].clientY;
            const diffX = Math.abs(startX.current - currentX);
            const diffY = Math.abs(startY.current - currentY);

            if (diffX > diffY && diffX > 10) {
                isSwiping.current = true;
            }
        }
        handleTouchMove(e);
    };

    const connectToPah = async ({ card, yesNo }) => {
        try {

            console.log('Approving post:', card.copy);

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "chat_id": "19:770f6ceccf314c80a1e96fe4bd279bb4@thread.v2",
                "text": `${yesNo ? '✅' : '❌'} - Post >> ${card.copy}`
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://prod-19.brazilsouth.logic.azure.com:443/workflows/db90f7b9bcc14592bfaaa82cb6501c49/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=FCmjPNnhmmXkjxBAjSKLkMO23EhZpRfZQZMyng0YAJ4", requestOptions)
                .then((response) => response.text())
                .then((result) => console.log(result))
                .catch((error) => console.error(error));

        } catch (error) {
            console.error('Error approving post:', error);
        }
    };

    const handleApprove = async (card) => {
        console.log('Aprobar publicación:', card.id);
        await connectToPah({ card, yesNo: true });
        nextPost('right');
    };

    const handleReject = async (card) => {
        console.log('Rechazar publicación:', card.id);
        await connectToPah({ card, yesNo: false });
        nextPost('left');
    };

    const handleComment = () => {
        alert('Próximamente...');
    };

    const nextPost = (direction) => {
        setSwipeDirection(direction);
        setImageLoaded(false);
        setTimeout(() => {
            const currentIndex = posts.findIndex(p => p.id === currentPost.id);
            if (currentIndex < posts.length - 1) {
                setCurrentPost(posts[currentIndex + 1]);
            } else {
                setCurrentPost(null);
            }
            setSwipeDirection(null);
            if (cardRef.current) {
                cardRef.current.style.transform = 'translateX(0) rotate(0deg)';
            }
        }, 300);
    };

    if (!currentPost) {

        return <div className="fixed inset-0 bg-gradient-to-bl from-cyan-900 to-gray-800 flex flex-col overflow-hidden touch-none">
            <div className="flex-grow flex items-center justify-center p-4">
                <div className="text-center mt-10 text-white">No hay más publicaciones disponibles. 💕</div>
            </div>
        </div>
    }

    return (
        <div className="fixed inset-0 bg-gradient-to-bl from-cyan-900 to-gray-800 flex flex-col overflow-hidden touch-none">
            <div className="flex-grow flex items-center justify-center p-4">
                <div ref={cardRef}
                    className={`w-[90vw] max-w-md bg-white rounded-2xl shadow-xl overflow-hidden ${swipeDirection === 'left' ? 'animate-swipe-left' :
                        swipeDirection === 'right' ? 'animate-swipe-right' : ''
                        }
                `}>
                    <div
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMovePotential}
                        onTouchEnd={handleTouchEnd}
                        className={`relative transition-all duration-300 ease-out 
                     
                    `}
                    >
                        <div className="aspect-w-3 aspect-h-4 w-full">
                            {!imageLoaded && (
                                <div className="absolute inset-0 "></div>
                            )}
                            <img
                                src={currentPost.source}
                                alt="Imagen de la publicación"
                                className={`w-full h-full object-cover transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
                                onLoad={() => setImageLoaded(true)}
                            />
                        </div>
                        <div className="p-6">
                            <p className="text-lg text-gray-800">{currentPost.copy}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white py-4 px-6 shadow-md">
                <div className="flex justify-around max-w-md mx-auto">
                    <button
                        onClick={async () => await handleReject(currentPost)}
                        className="p-3 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-200 transform hover:scale-110"
                    >
                        <X size={24} />
                    </button>
                    <button
                        onClick={handleComment}
                        className="p-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 transform hover:scale-110"
                    >
                        <MessageCircle size={24} />
                    </button>
                    <button
                        onClick={async () => await handleApprove(currentPost)}
                        className="p-3 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-200 transform hover:scale-110"
                    >
                        <Heart size={24} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Okeyador;